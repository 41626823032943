

const ContactDetails = () => {

    return (
        <form>

        </form>
    )
}

export default ContactDetails;